<div id="bg-overlay"></div>

<div class="about-wrapper">
  <div class="about-image">
    <img class="about-img" src="assets/about.png" alt="about-img" />
    <img class="about-img1" src="assets/about1.png" alt="about-img" />
  </div>
  <div class="about">
    <p class="title">
      Over <br />
      Madelief
    </p>
    <div class="underline"></div>
    <p class="about-header">
      Mijn naam is Madelief van Ginkel, maar online beter bekend als Madestout.
      Een 26 jarige vrouw met een grote passie voor Gaming en Tech. <br /><br />

      Op mijn vierde kreeg ik mijn eerste setup, een beetje overdreven. Dat was
      niet voor niets want mijn vader werkt in de IT branche, van hem heb ik
      veel geleerd. Daarnaast was mijn broer mijn grootste voorbeeld, van hem
      keek ik dan ook het gamen af. Hij zorgde ervoor dat ik mijn weg kon vinden
      en versloeg de moeilijkste bosses. Door de jaren heen heb ik heel wat
      games en consoles verslonden, zal het in mijn DNA hebben gezeten?<br /><br />

      Offline zat ik ook niet stil, nog altijd speel ik viool. Op mijn achtste
      begon ik met lessen nemen, waarna ik op mijn twaalfde ook in een orkest
      zat. Muziek maken en andere creatieve uitingen hielden mij altijd bezig.
      <br /><br />

      Ondanks dat ik vrij nieuw ben in de game industrie als streamer heb ik al
      veel mogen bereiken. Ik werk hard en blijf innovatief. Van het organiseren
      van meetups tot aan het bezoeken van events, ik zit nooit stil. Via Twitch
      en Discord heb ik het meeste contact met mijn kijkers. Daarnaast vind ik
      het bijhouden van mijn social media ook erg belangrijk, zo ben ik erg
      actief op Instagram, Snapchat en Twitter. <br /><br />
    </p>
  </div>
</div>
