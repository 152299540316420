import {
  faTwitch,
  faTwitter,
  faInstagram,
  faDiscord,
  faYoutube,
  faTiktok,
} from '@fortawesome/free-brands-svg-icons';
import {
  faArrowLeft,
  faBan,
  faBolt,
  faCheck,
  faCopy,
  faCross,
  faEllipsisV,
  faPause,
  faPenAlt,
  faPlay,
  faPlus,
  faRedo,
  faSearch,
  faSignOutAlt,
  faStop,
  faSync,
  faTimes,
  faTrash,
  faUserAstronaut,
  faUserSecret,
  faFileSignature,
  faUsers,
  faStopwatch,
} from '@fortawesome/free-solid-svg-icons';

export const icons = {
  faPlus,
  faArrowLeft,
  faBan,
  faCross,
  faTrash,
  faTimes,
  faCheck,
  faCopy,
  faPenAlt,
  faBolt,
  faSignOutAlt,
  faEllipsisV,
  faPause,
  faRedo,
  faSync,
  faSearch,
  faUserSecret,
  faPlay,
  faStop,
  faTwitch,
  faTwitter,
  faInstagram,
  faDiscord,
  faYoutube,
  faTiktok,
  faUserAstronaut,
  faFileSignature,
  faUsers,
  faStopwatch,
};
