import { Routes } from '@angular/router';
import { AboutMadeliefComponent } from './about-madelief/about-madelief.component';
import { ContactComponent } from './contact/contact.component';
import { GearComponent } from './gear/gear.component';
import { HomeComponent } from './home/home.component';
import { VriendenboekjeComponent } from './vriendenboekje/vriendenboekje.component';
import { RateComponent } from './rate/rate.component';
import { TopComponent } from './top/top.component';
//import { HamburgerComponent} from 

export const routes: Routes = [
  {
    path: '',
    data: { animationState: 'home' },
    component: HomeComponent,
  },
  {
    path: 'about',
    data: { animationState: 'about' },
    component: AboutMadeliefComponent,
  },
  {
    path: 'gear',
    data: { animationState: 'gear' },
    component: GearComponent,
  },
  {
    path: 'contact',
    data: { animationState: 'contact' },
    component: ContactComponent,
  },

  {
    path: 'vriendenboekje',
    data: { animationState: 'vriendenboekje' },
    component: VriendenboekjeComponent,
  },
  {
    path: 'rate',
    data: { animationState: 'rate' },
    component: RateComponent,
  },
  {
    path: 'top',
    data: { animationState: 'top' },
    component: TopComponent,
  }
];
