import {
  animate,
  animateChild,
  group,
  query,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

const animationStart = (rotate: any) => [
  query(
    ':enter, :leave',
    [
      style({
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
      }),
    ],
    { optional: true }
  ),
  query(
    ':enter',
    style({
      opacity: 0,
    }),
    { optional: true }
  ),
  query(
    ':leave',
    style({
      opacity: 1,
    }),
    { optional: true }
  ),
  group([
    query(
      '.animated',
      animate(
        '0.75s ease-in-out',
        style({
          zIndex: 1,
          background: '#444',
          transform: 'rotate(-35deg) scale(4)',
        })
      ),
      {
        optional: true,
      }
    ),
    query(
      '.home-animation',
      animate(
        '0.4s ease-in',
        style({
          transform: `rotate(${rotate}deg)`,
        })
      ),
      {
        optional: true,
      }
    ),
    query(':leave', [animate('0.2s ease-out', style({ opacity: 0 }))], {
      optional: true,
    }),
  ]),
];

const animationEnd = (rotate: number) => [
  query(':leave', animateChild(), { optional: true }),
  group([
    query(
      '.animated',
      animate(
        '0.4s ease-in',
        style({
          zIndex: 1,
          background: 'white',
          transform: 'rotate(-35deg)',
        })
      ),
      {
        optional: true,
      }
    ),
    query(
      '.home-animation',
      animate(
        '0.4s ease-in',
        style({
          transform: `rotate(${rotate}deg)`,
        })
      ),
      {
        optional: true,
      }
    ),
    query(':enter', [animate('0.3s ease-in-out', style({ opacity: 1 }))], {
      optional: true,
    }),
  ]),
  query(':enter', animateChild(), { optional: true }),
];

export const routeTransitionAnimations = trigger('routeTransition', [
  transition('* => *', [...animationStart(0), ...animationEnd(0)]),
]);

@NgModule({
  imports: [CommonModule, BrowserAnimationsModule],
  exports: [BrowserAnimationsModule],
})
export class RouteTransitionAnimationModule {}
