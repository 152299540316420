<div id="bg-overlay"></div>

<div class="home-content">
  <!-- <video class="logo" onloadedmetadata="this.muted=true" playsinline autoplay loop muted preload="auto">
    <source src="../../assets/logo.webm" type="video/webm" />
  </video> -->
  <img id="logo" src="../../assets/logogif.gif" alt="logo">
  <h1>Madestout</h1>
  <a href="https://twitch.tv/madestout" target="blank_"
    ><button mat-raised-button>
      <fa-icon [icon]="icons.faTwitch"></fa-icon>
      <span>Kijk op Twitch</span>
    </button>
  </a>
</div>
