import { Component } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { icons } from './icons';
import { routeTransitionAnimations } from './route-transition-animation.module';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [routeTransitionAnimations],
})
export class AppComponent {
  icons = icons;
  title = 'madestout-website';

  prepareRoute(outlet: RouterOutlet) {
    return outlet?.activatedRouteData?.animationState;
  }
}
