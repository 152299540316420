<div id="bg-overlay"></div>

<div class="rate">
    <p class="title">Rate van Made</p>
    <div class="underline"></div>

    <div class="container">

    <iframe class="iframe" style="left:0;right:0;top:0;bottom:0;width:100%;height:100%;position:absolute;" src="https://docs.google.com/document/d/1Oym3EksI_uTeROeFY00p2qQ6ei5Ddj2lQ3LElHzDBDM/preview"></iframe>

    </div>
    </div>

