import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-vriendenboekje',
  templateUrl: './vriendenboekje.component.html',
  styleUrls: ['./vriendenboekje.component.scss']
})
export class VriendenboekjeComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
