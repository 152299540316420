<div id="bg-overlay"></div>


<div class="gear-wrapper">
    <p class="header">
        Tijdens het streamen maak ik gebruik van verschillende apparatuur. Daar 
wordt dan ook vaak naar gevraagd in mijn streams. <br><br>

Het leek mij daarom handig om deze in een lijst weer te geven. 

    </p>

    <div class="underline"></div>

    <div class="category-wrapper">
        <p class="category-name">ACCESOIRES</p>

        <div class="left"><div class="prod-container"> <img class="prod-image" src="assets/gxt960.jpg" alt=""></div>
        <a href="https://www.google.com/search?q=gxt+graphin"><p class="description">GXT 960 Graphin</p></a> </div>

        <div class="right"><div class="prod-container"> <img class="prod-image" src="assets/gxt833.png" alt=""></div>
        <a href="https://www.google.com/search?q=gxt+torac"><p class="description">GXT 833 Thado</p></a> </div>

        <div class="left"><div class="prod-container"> <img class="prod-image" src="assets/gxt856.png" alt=""></div>
        <a href="https://www.trust.com/en/product/23577-gxt-856-torac-illuminated-gaming-keyboard"><p class="description">GXT 856 TORAC</p></a> </div>

        <div class="right"><div class="prod-container"> <img class="prod-image" src="assets/streamdeck.jpg" alt=""></div>
        <a href="https://www.google.com/search?q=elgato+streamdeck"><p class="description">Elgato StreamDeck</p></a></div>

    </div>

    

    <div class="underline"></div>

    <div class="category-wrapper-capture-cards">
        <p class="category-name">VIDEO</p>

        <div class="left"><div class="prod-container"> <img class="prod-image" src="assets/sony.png" alt=""></div>
        <a href="https://www.google.com/search?q=sony+a5100"><p class="description">Sony A5100</p></a> </div>

        <div class="right"><div class="prod-container"> <img class="prod-image" src="assets/lens.jpg" alt=""></div>
        <a href="https://www.google.com/search?q=e-mount+16-50mm+lens"><p class="description">E-mount 16-50mm lens</p></a></div>

        </div>

    <div class="underline"></div>

    <div class="category-wrapper">
        <p class="category-name">AUDIO</p>

        <div class="left"><div class="prod-container"> <img class="prod-image" src="https://images-na.ssl-images-amazon.com/images/I/31xp54jfjrL._AC_.jpg" alt=""></div>
        <a href="https://www.google.com/search?q=rode+procaster"><p class="description">Rode Procaster</p></a> </div>

        <div class="right"><div class="prod-container"> <img class="prod-image" src="https://images-na.ssl-images-amazon.com/images/I/81wV-rwxx3L._AC_SL1500_.jpg" alt=""></div>
        <a href="https://www.google.com/search?q=go+xlr"><p class="description">GO XLR Audio Interface</p></a> </div>

        <div class="left"><div class="prod-container"> <img class="prod-image" src="assets/gxt450.png" alt=""></div>
        <a href="https://www.trust.com/en/product/23191-gxt-450-blizz-rgb-7-1-surround-gaming-headset"><p class="description">GXT 450 BLIZZ</p></a> </div>

        <div class="right"><div class="prod-container"><img class="prod-image" src="assets/gxt414.png" alt=""></div>
        <a href="https://www.trust.com/en/product/23310-gxt-414-zamak-premium-multiplatform-gaming-headset"><p class="description">GXT 414 ZAMAK</p></a> </div>
        
    </div>

    <div class="underline"></div>

    <div class="category-wrapper-capture-cards">
        <p class="category-name">CAPTURE CARDS</p>

        <div class="left"><div class="prod-container"> <img class="prod-image" src="https://images-na.ssl-images-amazon.com/images/I/51zHw-6vMJL._AC_SL1171_.jpg" alt=""></div>
        <a href="https://www.google.com/search?q=elgato+hd60s"><p class="description">Elgato HD60S</p></a> </div>

        <div class="right"><div class="prod-container"> <img class="prod-image" src="https://images-na.ssl-images-amazon.com/images/I/61pWBRbG98L._AC_SL1500_.jpg" alt=""></div>
        <a href="https://www.google.com/search?q=elgato+camlink"><p class="description">Elgato CamLink 4K</p></a></div>

        </div>

    <div class="underline"></div>
    
    <div class="category-wrapper">
        <p class="category-name">COMPONENTEN</p>

        <div class="left"><div class="prod-container"> <img class="prod-image" src="https://images-na.ssl-images-amazon.com/images/I/61vGQNUEsGL._AC_SL1384_.jpg" alt=""></div>
        <a href="https://www.google.com/search?q=ryzen+5+5600x"><p class="description">Ryzen 5 5600X</p></a> </div>

        <div class="right"><div class="prod-container"> <img class="prod-image" src="https://images-na.ssl-images-amazon.com/images/I/51W-O4Jn9EL._AC_SL1000_.jpg" alt=""></div>
        <a href="https://www.google.com/search?q=ryzen+5+2600x"><p class="description">Ryzen 5 2600X</p></a></div>

        <div class="left"><div class="prod-container"> <img class="prod-image" src="https://www.megekko.nl/productimg/304170/midi/2_EVGA-GeForce-RTX-3060-XC-Gaming-Dual-Fan-Videokaart.jpg" alt=""></div>
        <a href="https://www.google.com/search?q=evga+rtx+3060"><p class="description">EVGA GEFORCE RTX 3060</p></a> </div>

        <div class="right"><div class="prod-container"> <img class="prod-image" src="https://images-na.ssl-images-amazon.com/images/I/71Kkm5nIRKL._AC_SL1500_.jpg" alt=""></div>
        <a href="https://www.google.com/search?q=corsair+vengeance+3200mhz"><p class="description">Corsair Vengeance 32GB @3200MHz</p></a></div>

        </div>
</div>

