<video id="bg-vid" oncanplay="this.play()" onloadedmetadata="this.muted = true" playsinline autoplay loop muted preload="auto">
  <source id="webm" src="../assets/bgmp4.mp4" type="video/mp4" />
</video>

<div class="content" [@routeTransition]="prepareRoute(outlet)">
  <div class="home-animation">
    <div class="top-left-block animated"></div>
    <div class="bottom-right-block animated"></div>
  </div>

      <!-- Hamburger start -->

      <div class="hamburger-button">
        <input type="checkbox" id="openSidebarMenu">
        <label for="openSidebarMenu" class="sidebarIconToggle" [matMenuTriggerFor]="hamburgerMenu" >
          <div class="spinner diagonal1"></div>
          <div class="spinner horizontal"></div>
          <div class="spinner diagonal2"></div>
        </label>
      </div>

  <mat-menu #hamburgerMenu="matMenu">
    <button mat-menu-item routerLink="./" routerLinkActive #homeActive="routerLinkActive">Home</button>
    <button mat-menu-item [matMenuTriggerFor]="aboutMenu">About</button>
    <a text-decoration="none" href="https://madestout.nl/merch" target="blank_"><button mat-menu-item >Merch</button></a>
    <button mat-menu-item routerLink="./contact" routerLinkActive #contactActive="routerLinkActive">Contact</button>
  </mat-menu>

      <!-- Hamburger end -->

  <nav>
    <a routerLink="./" routerLinkActive #homeActive="routerLinkActive">Home</a>
    <a [matMenuTriggerFor]="aboutMenu"> About </a>
    <a href="https://madestout.nl/merch" target="blank_"> Merch </a>
    <a
      routerLink="./contact"
      routerLinkActive
      #contactActive="routerLinkActive"
    >
      Contact
    </a>
    <mat-menu #aboutMenu="matMenu">
      <button
        mat-menu-item
        routerLink="./about"
        routerLinkActive
        #aboutActive="routerLinkActive"
      >
        Over Madelief
      </button>
      <button
        mat-menu-item
        routerLink="./gear"
        routerLinkActive
        #aboutActive="routerLinkActive"
      >
        Gear
      </button>
    </mat-menu>
  </nav>
  <div class="scrollable-content">
    <router-outlet #outlet="outlet"></router-outlet>
  </div>
  <div class="socials">
    <a href="https://twitch.tv/madestout" target="blank_" matTooltip="Twitch"><fa-icon [icon]="icons.faTwitch"></fa-icon></a>
    <a href="https://www.instagram.com/stoutmade/" target="blank_" matTooltip="Instagram"><fa-icon [icon]="icons.faInstagram"></fa-icon></a>
    <a href="https://twitter.com/Madestout" target="blank_" matTooltip="Twitter"><fa-icon [icon]="icons.faTwitter"></fa-icon></a>
    <a href="https://discord.gg/YraMrHw" target="blank_" matTooltip="Discord"><fa-icon [icon]="icons.faDiscord"></fa-icon></a>
    <a href="https://www.youtube.com/channel/UCPbs2Yq_4CHGShYAwaBeYWA" target="blank_" matTooltip="YouTube"><fa-icon [icon]="icons.faYoutube"></fa-icon></a>
    <a href="https://www.tiktok.com/@stoutmade?" target="blank_" matTooltip="TikTok"><fa-icon [icon]="icons.faTiktok"></fa-icon></a>
  </div>
</div>
