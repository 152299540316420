import { NgModule } from '@angular/core'; 
import { routes } from './app.routes';
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { RouterModule } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { MaterialModule } from './material.module';
import { AboutMadeliefComponent } from './about-madelief/about-madelief.component';
import { RouteTransitionAnimationModule } from './route-transition-animation.module';
import { ContactComponent } from './contact/contact.component';
import { GearComponent } from './gear/gear.component';
import { ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from "@angular/common/http";
import { VriendenboekjeComponent } from './vriendenboekje/vriendenboekje.component';
import { RateComponent } from './rate/rate.component';
import { TopComponent } from './top/top.component';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    AboutMadeliefComponent,
    ContactComponent,
    GearComponent,
    VriendenboekjeComponent,
    RateComponent,
    TopComponent,
  ],
  imports: [
    RouteTransitionAnimationModule,
    RouterModule.forRoot(routes),
    BrowserAnimationsModule,
    FontAwesomeModule,
    MaterialModule,
    ReactiveFormsModule,
    HttpClientModule,
  ],
  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule {}
