<div id="bg-overlay"></div>

<p class="title">Contact</p>
<div class="underline"></div>

<div class="contact-wrapper">
    <p class="header">Waar kunnen wij je mee helpen?</p>
<div class="contact">
   <div class="email-wrapper"> <div id="email-img"><img src="../../assets/email.png" alt="email"> </div> <div id="email-desc"><p>Persoonlijk bericht aan Madelief? <br> <span id="answer">madelief@madestout.nl</span></p></div></div>
   <div class="order-wrapper"><div id="order-img"><img src="../../assets/order.png" alt="order"> </div> <div id="order-desc"><p>Vragen over je bestelling? <br> <span id="answer"> klantenservice@madestout.nl</span></p></div></div>
</div>


<div class="right-side">
    <div class="socials">
        <div id="socials-img"><img src="../../assets/socials.png" alt="social"></div> <p id="socials">Social media?</p>
        <div id="socials-logos"><a href="https://discord.gg/YraMrHw" target="blank_"><img src="../../assets/discord.png" alt="discord"></a><a href="https://instagram.com/stoutmade/" target="blank_"><img src="../../assets/instagram.png" alt="insta"></a><a href="https://twitter.com/madestout" target="blank_"><img src="../../assets/twitter.png" alt="twitter"></a></div>
        </div>

</div>
</div>